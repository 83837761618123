// app/javascript/controllers/font_item_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radio", "indicator"]
  static values = { font: String }

  select() {
    this.radioTarget.checked = true
    this.element.closest("#font_list").querySelectorAll("[data-font-item-target='indicator']").forEach(el => {
      el.classList.remove("bg-blue-500")
    })
    this.indicatorTarget.classList.add("bg-blue-500")
    
    // Enable the Apply Font button
    const applyButton = this.element.closest("[data-controller='modal']").querySelector("[data-font-picker-target='selectButton']")
    applyButton.disabled = false
    
    // Update the hidden input with the selected font
    const hiddenInput = this.element.closest("[data-controller='modal']").querySelector("#selected_font")
    hiddenInput.value = this.fontValue

    // Load and apply the font for preview
    this.loadFont(this.fontValue)
  }

  loadFont(fontFamily) {
    const link = document.createElement('link')
    link.href = `https://fonts.bunny.net/css?family=${encodeURIComponent(fontFamily)}`
    link.rel = 'stylesheet'
    document.head.appendChild(link)

    // Apply the font to the sample text
    const sampleText = this.element.closest("[data-controller='modal']").querySelector("[data-font-picker-target='sampleText']")
    sampleText.style.fontFamily = fontFamily
  }
}