import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.addSectionLinks = this.element.querySelectorAll('.add-section-link');
    this.addSectionLinks.forEach((link) => {
      link.addEventListener('click', this.addSection.bind(this));
    });
  }

  addSection(event) {
    event.preventDefault();
    const contentType = event.target.getAttribute('data-content-type');
    const timestamp = new Date().getTime();
    const sectionFields = `
      <div class="nested-fields border border-gray-300 p-4 rounded" data-target="page-sections.container">
        <input type="hidden" name="page[page_sections_attributes][${timestamp}][content_type]" value="${contentType}">
        <!-- Add fields for the selected content type -->
        ${contentType === 'ContentWithImage' ? `
          <div class="mb-4">
            <label for="page_page_sections_attributes_${timestamp}_content_with_image_attributes_title" class="block text-sm font-medium text-gray-700">Title</label>
            <input type="text" name="page[page_sections_attributes][${timestamp}][content_with_image_attributes][title]" id="page_page_sections_attributes_${timestamp}_content_with_image_attributes_title" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="mb-4">
            <label for="page_page_sections_attributes_${timestamp}_content_with_image_attributes_image" class="block text-sm font-medium text-gray-700">Image</label>
            <input type="file" name="page[page_sections_attributes][${timestamp}][content_with_image_attributes][image]" id="page_page_sections_attributes_${timestamp}_content_with_image_attributes_image" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
          </div>
        ` : ''}
        <a href="#" class="remove-section-link text-red-500 hover:text-red-700 underline" data-action="click->page-sections#removeSection">Remove Section</a>
      </div>
    `;
    this.containerTarget.insertAdjacentHTML('beforeend', sectionFields);
  }

  removeSection(event) {
    event.preventDefault();
    event.target.closest('.nested-fields').remove();
  }
}