<template>
  <div class="relative inline-block text-left"  ref="dropdownElement">
    <div>
      <button type="button" aria-haspopup="true" class="iinline-flex items-center justify-center rounded-lg border border-gray-300 bg-white p-1.5 text-gray-700 transition-all duration-200 hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2" @click="toggleDropdown">
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="5" cy="12" r="1"></circle><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle></svg>
      </button>
    </div>

    <div v-show="dropdownVisible" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10" role="menu" aria-orientation="vertical">
      <div class="py-1" role="none">
        <a :href="publicProfileUrl" target="_blank" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Public Profile</a>
        <a :href="editUrl" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Edit</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    publicProfileUrl: {
      type: String,
      required: true,
    },
    editUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dropdownVisible = ref(false);
    const dropdownElement = ref(null);

    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    const handleClickOutside = (event) => {
      if (dropdownElement.value && !dropdownElement.value.contains(event.target)) {
        dropdownVisible.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      dropdownVisible,
      toggleDropdown,
      dropdownElement,
    };
  },
};
</script>