// app/javascript/controllers/font_picker_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "fontList", "fontTypeLabel"]

  connect() {
    this.fonts = [
      "Alatsi", "Albert Sans", "Aldrich", "Alef", "Alegreya", "Alegreya Sans", "Alegreya Sans SC"
      // Add more fonts here...
    ]
    this.currentFontType = null
  }

  openModal(event) {
    this.currentFontType = event.currentTarget.dataset.fontType
    this.fontTypeLabelTarget.textContent = this.currentFontType
    this.modalTarget.style.display = "block"
    this.populateFontList()
  }

  closeModal() {
    this.modalTarget.style.display = "none"
  }

  populateFontList() {
    this.fontListTarget.innerHTML = this.fonts.map(font => `
      <div>
        <input type="radio" name="font" value="${font}" id="${font}">
        <label for="${font}">${font}</label>
      </div>
    `).join('')
  }

  searchFonts(event) {
    const searchTerm = event.target.value.toLowerCase()
    const filteredFonts = this.fonts.filter(font => font.toLowerCase().includes(searchTerm))
    this.fontListTarget.innerHTML = filteredFonts.map(font => `
      <div>
        <input type="radio" name="font" value="${font}" id="${font}">
        <label for="${font}">${font}</label>
      </div>
    `).join('')
  }

  selectFont() {
    const selectedFont = this.fontListTarget.querySelector('input[name="font"]:checked')
    if (selectedFont) {
      const fontName = selectedFont.value
      const fontType = this.currentFontType === 'heading' ? 'heading_font' : 'content_font'
      
      fetch('/settings/design', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          settings_design: {
            [fontType]: fontName
          }
        })
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          location.reload() // Reload the page to reflect changes
        } else {
          alert('Failed to update font. Please try again.')
        }
      })
      
      this.closeModal()
    } else {
      alert('Please select a font.')
    }
  }
}