import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  toggleDropdown() {
    this.menuTarget.classList.toggle('hidden');
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add('hidden');
    }
  }
}